import PropTypes from 'prop-types';
import cx from 'classnames';

import Checkbox from 'core/components/Checkbox';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';
import { capitalize } from 'core/utils/string-helper';

import renameThemeProp from 'site/components/renameThemeProp';

import styles from './index.styl';


function Option(props) {
  const {
    isSelected,
    isMulti,
    value,
    innerProps,
    innerRef,
    label,
    theme: {
      colors: {
        primary,
        dividerLight,
      },
      controls: {
        chipOption: {
          single: singleChipAtoms,
          multi: multiChipAtoms,
        },
      },
    },
  } = props;

  const chipAtoms = isMulti ? multiChipAtoms : singleChipAtoms;

  return (
    <div
      className={cx(
        styles.option,
        isSelected && styles._isSelected,
      )}
      ref={innerRef}
    >
      <style jsx>{`
        .${styles.option}
          color ${primary}
          background ${chipAtoms.idle.backgroundColor}

          &:hover
            background ${chipAtoms.hover.backgroundColor}

          &.${styles._isSelected}
            background ${chipAtoms.selected.backgroundColor}

            &:hover
              background ${chipAtoms.selectedHover.backgroundColor}

          & + &
            .${styles.value}
            .${styles.checkbox}
              &:after
                background ${dividerLight}
      `}</style>
      {isMulti ? (
        <div className={styles.checkbox}>
          <Checkbox
            {...innerProps}
            name={value}
            type='secondary'
            onChange={innerProps.onClick}
            checked={isSelected}
          >
            {capitalize(label)}
          </Checkbox>
        </div>
      ) : (
        <div
          className={styles.value}
          {...innerProps}
        >
          {capitalize(label)}
        </div>
      )}
    </div>
  );
}

const chipOptionStyles = `{
  backgroundColor,
}`;

const chipOptionStates = `{
  idle: ${chipOptionStyles},
  hover: ${chipOptionStyles},
  selected: ${chipOptionStyles},
  selectedHover: ${chipOptionStyles},
}`;

Option.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  theme: themePropTypes(`{
    colors: {
      dividerLight,
    },
    controls: {
      chipOption: {
        single: ${chipOptionStates},
        multi: ${chipOptionStates},
      }
    }
  }`),
  isMulti: PropTypes.bool,
  innerRef: PropTypes.func,
  innerProps: PropTypes.object,
};

export default renameThemeProp(withTheme(Option));
