import { useContext } from 'react';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import { GeoContext } from 'site/components/GeoContext';

import { servicesApi } from 'site/api/definitions/services';

import { generateSelectOptions } from 'site/utils/forms';
import { formatGenerations } from 'site/utils';

import { RANGES } from './constants';


const getFormOptions = (dictionaries = {}) => ({
  filteredBodies: generateSelectOptions(dictionaries.body, { value: 'attributes.slug' }),
  filteredConditions: generateSelectOptions(dictionaries.condition, { label: 'title', value: 'value' }),
  filteredTransmissions: generateSelectOptions(dictionaries.transmission, { label: 'title', value: 'value' }),
  filteredEngineTypes: generateSelectOptions(dictionaries.engineType, { label: 'title', value: 'value' }),
  filteredDealerType: generateSelectOptions(dictionaries.dealerType, { label: 'title', value: 'value' }),
  filteredCountries: generateSelectOptions(dictionaries.country, { label: 'title', value: 'value' }),
  filteredClasses: generateSelectOptions(dictionaries.classes, { label: 'attributes.name', value: 'attributes.url' }),
  filteredAssemblies: generateSelectOptions(dictionaries.assembly, { label: 'title', value: 'value' }),
  filteredBoosts: generateSelectOptions(dictionaries.boost, { label: 'title', value: 'value' }),
  filteredControls: generateSelectOptions(dictionaries.control, { label: 'title', value: 'value' }),
  filteredSeats: dictionaries.seats,
});

/* eslint-disable react/prop-types */
export default function withContent(Component) {
  return function WrappedComponent(props) {
    const { geoSlug } = useContext(GeoContext);

    const {
      match: {
        params: {
          brand: brandSlug,
          model: modelSlug,
          geo,
        },
      },
    } = props;

    const results = useRequest({
      queries: [
        {
          queryKey: ['servicesApi', 'getOffersDictionaries'],
          queryFn: ({ queryKey: [, method] }) => servicesApi[method](),
          enabled: process.env.BROWSER_RUNTIME,
        }, {
          queryKey: ['servicesApi', 'getOffersGeos', { 'filter[brand]': brandSlug }],
          queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
          select: denormalizeData,
          enabled: process.env.BROWSER_RUNTIME,
        }, {
          queryKey: ['servicesApi', 'getBrands', { 'filter[with_offers_from_geo]': geo || geoSlug }],
          queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
          select: denormalizeData,
          enabled: process.env.BROWSER_RUNTIME,
        },
      ],
    });

    const {
      data: [
        dictionaries = {},
        geos = [],
        brands = [],
      ],
      isLoading,
    } = processQueryResults(results);

    const { data: models = [] } = useRequest({
      queryKey: ['servicesApi', 'getModels', {
        'filter[with_offers_from_geo]': geo || geoSlug,
        'filter[brand]': brandSlug,
        'relations[car_model]': 'brand',
        include: 'car_brand',
      }],
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      select: denormalizeData,
      enabled: process.env.BROWSER_RUNTIME && !!brandSlug,
    });

    const { data: generations = [] } = useRequest({
      queryKey: ['servicesApi', 'getGenerations', {
        'filter[with_offers_from_geo]': geo || geoSlug,
        'filter[brand]': brandSlug,
        'filter[model]': modelSlug,
        'relations[car_generation]': 'model',
        include: 'car_model',
      }],
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      select: data => formatGenerations(denormalizeData(data)),
      enabled: process.env.BROWSER_RUNTIME && !!modelSlug,
    });

    const formData = {
      formOptions: getFormOptions(dictionaries),
      ranges: {
        ...dictionaries.ranges,
        ...RANGES,
        year: dictionaries.ranges?.year || RANGES.year,
        price: dictionaries.ranges?.price || RANGES.price,
      },
    };

    return (
      <Component
        {...props}
        brands={brands}
        models={models}
        generations={generations}
        geos={geos}
        formData={formData}
        isLoading={isLoading}
      />
    );
  };
}
