import { useContext } from 'react';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import { GeoContext } from 'site/components/GeoContext';

import { servicesApi } from 'site/api/definitions/services';

import { generateSelectOptions } from 'site/utils/forms';

import { isMotoPath } from 'site/utils';


const getFormOptions = (brands = [], dealerNetworks = [], dealersGeos = []) => ({
  brandsDealersOptions: [{
    label: 'Марки',
    options: generateSelectOptions(brands, { value: 'attributes.slug' }),
  }, {
    label: 'Автосалоны',
    options: generateSelectOptions(dealerNetworks),
  }],
  filteredGeos: generateSelectOptions(dealersGeos, { value: 'attributes.url' }),
});

/* eslint-disable react/prop-types */
export default function withContent(Component) {
  return function WrappedComponent(props) {
    const {
      match: {
        params: {
          geo: geoSlug,
        },
      },
      location,
    } = props;

    const isMoto = isMotoPath(location.pathname);
    const { geo: defaultGeo } = useContext(GeoContext);

    const results = useRequest({
      queries: [
        {
          queryKey: ['servicesApi', isMoto ? 'getMotoBrands' : 'getBrands', {
            'filter[with_offers_from_geo]': geoSlug,
          }],
          queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
          select: denormalizeData,
          enabled: process.env.BROWSER_RUNTIME,
        }, {
          queryKey: ['servicesApi', 'getDealersGeos'],
          queryFn: ({ queryKey: [, method] }) => servicesApi[method](),
          select: denormalizeData,
          enabled: process.env.BROWSER_RUNTIME,
        }, {
          queryKey: ['servicesApi', 'getDealersNetworks', {
            'filter[geo]': geoSlug,
            'filter[type]': isMoto ? 'moto' : 'car,lcv',
          }],
          queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
          select: denormalizeData,
          enabled: process.env.BROWSER_RUNTIME,
        },
      ],
    });

    const {
      data: [
        brands = [],
        dealerNetworks = [],
        dealersGeos = [],
      ],
      isLoading,
    } = processQueryResults(results);

    return (
      <Component
        {...props}
        formOptions={getFormOptions(brands, dealersGeos, dealerNetworks)}
        geo={defaultGeo}
        isLoading={isLoading}
      />
    );
  };
}
